/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @next/next/no-img-element */
import AOS from "aos";
import "aos/dist/aos.css";
import { appWithTranslation, useTranslation } from "next-i18next";
import type { AppProps } from "next/app";
import dynamic from "next/dynamic";
import Head from "next/head";
import { useRouter } from "next/router";
import NextNProgress from "nextjs-progressbar";
import { Fragment, useEffect } from "react";
import { useCookies } from "react-cookie";
import { hotjar } from "react-hotjar";
import "react-tooltip/dist/react-tooltip.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import ChatButton from "~/components/ChatButton";
import Footer from "~/components/Footer";
import Header from "~/components/Header";
import "~/styles/globals.css";
import "~/styles/icons.scss";
import "~/styles/text.scss";
import Trans from "~/types/translationKeys";
import { getQueryParams } from "~/utils/common";

const CookieBanner = dynamic(() => import("~/components/CookieBanner"), {
  ssr: false,
});

const App = ({ Component, pageProps }: AppProps) => {
  const router = useRouter();
  const [_, setCookie] = useCookies(["utm_source", "others"]);
  // const changeLanguage = useChangeLanguage();
  const { t } = useTranslation();

  useEffect(() => {
    if (typeof window !== "undefined") {
      const utmSource = router.query.utm_source as string;
      if (utmSource) {
        setCookie("utm_source", utmSource);
      }
    }
  }, [router.query.utm_source]);

  useEffect(() => {
    if (typeof window !== "undefined" && getQueryParams(router.asPath)) {
      setCookie("others", getQueryParams(router.asPath));
    }
  }, [router.asPath]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      AOS.init({ duration: 750, easing: "ease-in-out-cubic", once: true });
    }

    if (
      typeof window !== "undefined" &&
      process.env.NODE_ENV === "production"
    ) {
      hotjar.initialize(
        Number(process.env.NEXT_PUBLIC_HOTJAR_ID || "0"),
        Number(process.env.NEXT_PUBLIC_HOTJAR_SV || "0")
      );
    }

    // const lang = localStorage.getItem(LocalKeys.LANGUAGE) || navigator.language;

    // // language allow only en and vi
    // const allowedLanguage = ["en", "vi"];

    // if (allowedLanguage.includes(lang)) {
    //   changeLanguage(lang === "en" ? "en" : "vi");
    // }
  }, []);

  return (
    <Fragment>
      <Head>
        <title key="title">{t(Trans.seo_title)}</title>
        <meta key="mt:title" name="title" content={t(Trans.seo_title)} />
        <meta
          key="description"
          name="description"
          content={t(Trans.seo_description)}
        />

        <meta name="og:title" content={t(Trans.seo_title)} />
        <meta name="og:description" content={t(Trans.seo_description)} />
        <meta property="og:type" content="website" />
        <meta
          key="og:image"
          property="og:image"
          content="https://cxgenie.ai/sharing-image.png"
        />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta
          key="og:image:alt"
          property="og:image:alt"
          content={t(
            Trans.cx_genie_is_a_powerful_and_optimized_platform_for_enhancing_customer_engagement_with_minimal_effort
          )}
        />
        <meta
          key="twitter:title"
          name="twitter:title"
          content={t(Trans.seo_title)}
        />
        <meta
          key="twitter:description"
          name="twitter:description"
          content={t(Trans.seo_description)}
        />
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          key="twitter:image"
          name="twitter:image"
          content="https://cxgenie.ai/sharing-image.png"
        />
      </Head>

      <NextNProgress color={"#364de7"} height={5} />

      <main id="root">
        <Header />
        <Component {...pageProps} />
        <Footer />
        <CookieBanner />
      </main>

      <ChatButton />
    </Fragment>
  );
};

export default appWithTranslation(App);
